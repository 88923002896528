import React, { Component } from 'react';
import './Projects.css';
import treeImg from '../../images/tree.svg';
import SectionHeading from '../UI/sectionHeading/sectionHeading';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import ImgBox from '../Home/imgBox/imgBox';
import Project from './project/project';
import loanImg from '../../images/loan.png';
import carbonImg from '../../images/carbonMade.png';
import solarImg from '../../images/solar.png';
import paulkartImg from '../../images/paulKart.png';
import shoppingImg from '../../images/shopping.png';
import todoImg from '../../images/todo.png';
import chatImg from '../../images/chat.png';
import emailImg from '../../images/emailInput.png';
import searchBarImg from '../../images/searchBar.png';
import calendarImg from '../../images/calendar.png';
import planImg from '../../images/plan.png';
import burgerImg from '../../images/burger.jpg';
import InstImg from '../../images/inst.png';
import Grexter from '../../images/project/grexter.png'
import GrexterDash from '../../images/project/grexter-dashboard.png'
import colorPicker from '../../images/project/color.png'
import Cureit from '../../images/project/cureit.png'
import Favmovies from '../../images/project/favmovies.png'

class Projects extends Component {
    state = {
        img: treeImg,
        projects: [
            {
                img: loanImg,
                title: 'Loan App',
                catagory: 'web application',
                order: 10,
                width: 6,
                url: 'https://amount-response.herokuapp.com',
            },
            {
                img: InstImg,
                title: 'Insta-investment',
                catagory: 'web application',
                order: 9,
                width: 6,
                url: 'http://foxmantra.ml',
            },
            {
                img: carbonImg,
                title: 'Perfect Match',
                catagory: 'Design & Dev',
                color: '#000',
                order: 2,
                width: 6,
                url: 'https://happy-mestorf-83b459.netlify.com/',
            },
            {
                img: solarImg,
                title: 'Solar Animation',
                catagory: 'Design & Dev',
                color: '#000',
                order: 13,
                width: 6,
                url: 'https://vigilant-shockley-8b665f.netlify.com/',
            },
            {
                img: paulkartImg,
                title: 'Paul Kart',
                catagory: 'Design & Dev',
                color: '#000',
                order: 1,
                width: 6,
                url: 'https://condescending-fermat-307c69.netlify.com',
            },
            {
                img: shoppingImg,
                title: 'Shopping Kart',
                catagory: 'Design & Dev',
                color: '#000',
                order: 4,
                width: 6,
                url: 'https://elegant-feynman-c99c48.netlify.com',
            },
            {
                img: chatImg,
                title: 'Chat App',
                catagory: 'Design & UI',
                color: '#000',
                order: 8,
                width: 6,
                url: 'https://jovial-payne-b96556.netlify.com/',
            },
            {
                img: todoImg,
                title: 'Todo App',
                catagory: 'Design & UI',
                color: '#000',
                order: 6,
                width: 6,
                url: 'https://nifty-franklin-ce785c.netlify.com/',
            },
            {
                img: emailImg,
                title: 'Sample App',
                catagory: 'Design & UI',
                color: '#000',
                width: 6,
                url: 'https://vigorous-mcclintock-6f4deb.netlify.com/',
            },
            {
                img: searchBarImg,
                title: 'Leaf App',
                catagory: 'Design & UI',
                color: '#000',
                order: 3,
                width: 6,
                url: 'https://pedantic-wright-3ed01f.netlify.com/',
            },
            {
                img: calendarImg,
                title: 'Calendar App',
                catagory: 'Design & UI',
                color: '#000',
                order: 5,
                width: 6,
                url: 'https://awesome-tesla-4da0b1.netlify.com/',
            },
            {
                img: planImg,
                title: 'Planning App',
                catagory: 'Dev & UI',
                color: '#000',
                order: 12,
                width: 6,
                url: 'https://week-plan-v2.herokuapp.com',
            },
            {
                img: burgerImg,
                title: 'Burger App',
                catagory: 'Dev & UI',
                order: 11,
                color: '#000',
                width: 6,
                url: 'https://burger-app-dev-new.herokuapp.com',
            },
            {
                img: Grexter,
                title: 'Grexter Website',
                catagory: 'Design & Development',
                order: 14,
                color: '#000',
                width: 6,
                url: 'https://grexter.in',
            },
            {
                img: GrexterDash,
                title: 'Grexter Dashboard',
                catagory: 'Design & Development',
                order: 15,
                color: '#000',
                width: 6,
                url: 'https://login.grexter.in',
            },
            {
                img: colorPicker,
                title: 'Color Picker',
                catagory: 'Design & Development',
                order: 16,
                color: '#000',
                width: 6,
                url: 'https://color-animesh.herokuapp.com/',
            },
            {
                img: Favmovies,
                title: 'Movie Mania',
                catagory: 'Design & Development',
                order: 17,
                color: '#000',
                width: 6,
                url: 'https://animeshpaul123.github.io/favmovies',
            },
            {
                img: Cureit,
                title: 'Cureit',
                catagory: 'Design & Development',
                order: 18,
                color: '#000',
                width: 6,
                url: 'https://cureit.herokuapp.com/',
            },
        ]
    }
    render() {



        const project = this.state.projects.sort((a, b) => b.order - a.order).map((project, i) => {
            let styles = ["project-container"]
            let width = `col-md-${project.width}`
            styles.push(width)
            return (
                <Fade bottom delay={(i) * 100}>
                    <div className={styles.join(' ')}>
                        <a href={project.url} target="_blank">
                            <Project projectImg={project.img}
                                title={project.title}
                                catagory={project.catagory}
                                color={project.color} />
                        </a>
                    </div>
                </Fade>
            )
        })
        return (
            <div className="projects">
                <div className="image-wrapper">
                    <div className="image-container">
                        <ImgBox img={this.state.img} />
                    </div>
                    <div className="title-wrapper">
                        <Fade right>
                            <SectionHeading>My Works</SectionHeading>
                        </Fade>
                    </div>
                </div>
                <div className="project-wrapper">
                    <div className="title-wrapper">
                        <Fade bottom>
                            <SectionHeading>Projects</SectionHeading>
                        </Fade>
                    </div>
                    <div className="project-inner">
                        <div className="row">
                            {project}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Projects;